import React from 'react'
import { connect } from 'react-redux'
import ApiClient from 'client/ApiClient'
import ApiHelper from 'client/ApiHelper'
import { buildJSXFromHTML} from "util/utility"
import { Constants } from 'config/Constants.js'
import InfoPanel from "containers/InfoPanel/index.js"
import { Link } from 'react-router-dom'

class Library extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      content: null,
      material: [],
      filtersSelected: [],
      }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.filtersSelected !== state.filtersSelected) {
      return {
        filtersSelected: props.filtersSelected,
      }
    }
    return null
  }

  componentDidMount() {
    ApiClient.instance().fetchContent("node--page", {field_path:"/library"}, null, null, null, 0, function(node, included) {
      var body = (node[0].attributes.body || {}).value || ""
      var jsx = buildJSXFromHTML(body)

      this.setState({
        content: jsx
      })
    }.bind(this))

    this.update()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.filtersSelected !== prevProps.filtersSelected) {
      this.update(this.state.id)
    }
  }


  update() {
    ApiHelper.instance().buildFilter((filter) => {
      ApiClient.instance().fetchContent("node--tool", filter, "sort=title", null, Object.values(Constants.filterFieldMapping), 0, function(nodeEntities, included) {
        this.setState({
          material: nodeEntities,
          includedEntities: included
        })
      }.bind(this))
    })
    

  }

  render() {
    var materialTitle = (this.state.material.length > 0 ? "materials" : "no materials")
    if(this.state.filtersSelected.length > 0) {
      materialTitle += " (matching current filter)"
    }

    // <p>{node.attributes.field_short_description}</p>
    var material
    if(this.props.categoryNames && this.props.filterTermNames) {
      material = (this.state.material || []).map((node) => {
        const categories = node.relationships.field_category.data.map(category => this.props.categoryNames[category.id])
        const targetAudiences = node.relationships.field_target_audience.data.map(term => this.props.filterTermNames[term.id])

        return  <li key={node.id}>
                  <h4><Link to={(node.attributes.field_path ? node.attributes.field_path : `/${node.id}`)}>{node.attributes.title}</Link></h4>
                  {categories.join(", ")} | {targetAudiences.join(", ")}
                </li>
      })
    }

    return (
      <InfoPanel id={"library"} icon={`/images/icon-library.svg`} title={"Library"} color={"blue"}>
        {this.state.content}
        <h3>{materialTitle}</h3>
        <ul className="material">
          {material}
        </ul>
      </InfoPanel>
    )
  }
}

// update when language or filters change
const mapStateToProps = (state, ownProps) => ({
  filtersSelected: state.toolFiltersApplied,
  categoryNames: state.categoryNames,
  filterTermNames: state.filterTermNames
})

Library = connect(mapStateToProps)(Library)


export default Library
