import React from 'react'
import sizeMe from 'react-sizeme'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { setCategoryNames } from 'actions'

import ApiHelper from 'client/ApiHelper'

import { Constants } from 'config/Constants.js'

//import City from './MapLayout.js'
import SVGMap from "./SVGMap.js"
import SVG from 'react-inlinesvg';
import CategoryBox from "./CategoryBox.js"
//import Directions from "containers/TogglePanels/Directions.js"
import ToolFilters from "containers/TogglePanels/ToolFilters.js"

import Welcome from "containers/InfoPanel/Welcome.js"
import InfoCentre from "containers/InfoPanel/InfoCentre.js"
import Library from "containers/InfoPanel/Library.js"
import Category from "containers/InfoPanel/Category.js"
import Material from "containers/InfoPanel/Material.js"
import Direction from "containers/InfoPanel/Direction.js"

class Map extends React.Component {

  static contextTypes = {
    router: () => null
  }

  state = {
    welcome: true,
    data: null,
    filtersSelected: [],
    categoryNames: null,
    dimensions: {
      width: -1,
      height: -1
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.filtersSelected !== state.filtersSelected) {
      return {
        filtersSelected: props.filtersSelected,
      }
    }
    return null
  }

  componentDidMount() {
    this.update()

    if(this.props.match.isExact) {
      this.props.history.push('/welcome')
    }
  }


  componentDidUpdate(prevProps, prevState) {
    this.props.dispatch(setCategoryNames(this.state.categoryNames))

    if (this.props.filtersSelected !== prevProps.filtersSelected) {
      this.update()
    }
  }

  // flatten all categories in the hierarchy getting the ones that are parent but not grand parent, add a path like "3-1"
  flattenTree(tree) {
    var isGrandParent = function(node) {
      var reducer = (a, elem) => a + elem.children.length
      return node.children.reduce(reducer, 0) > 0
    }
    var goDeeper = function(node, res) {
      if(isGrandParent(node)) {
        for(var i=0; i<node.children.length; i++) {
          goDeeper(node.children[i], res)
        }
        node.grandparent = true
      } else {
        node.grandparent = false
      }
      res.push(node)
    }
    var result = []
    for(var i=0; i<tree.length; i++) {
      goDeeper(tree[i], result)
    }
    return result
  }

  // get the vocabulary with category labels to display on the map
  update() {
    ApiHelper.instance().clearCaches()
    ApiHelper.instance().buildContentHierarchy(function(hierarchy){
      const categoryNames = hierarchy.reduce((obj, term) => Object.assign({[term["id"]]: term.attributes.name}, obj), {})

      this.setState({
        data: hierarchy,
        //filtersSelected: filtersSelected,
        categoryNames: categoryNames
      })
    }.bind(this))
  }

  handleClick(e, target) {
    e.preventDefault()

    if(!this.props.didDrag) {
      this.props.history.push(target)
    }
  }


  render() {
    //console.log(this.state.filtersSelected)

    const mainBuildings = ["info-centre", "library"].map((id) => {
      const di = (Constants.map[id] ? Constants.map[id] : { x: 0, y: 0, w: 0, h: 0 })
      return (
        <foreignObject key={id} x={di.x} y={di.y} width={di.w} height={di.h}>
          <a href={`/${id}`} className="category-title-link" onClick={ (e) => { this.handleClick(e, `/${id}`) } }>
            <SVG src={`/images/${id}.svg`} uniquifyIDs={false} />
          </a>
        </foreignObject> 
      )
    })

    if(this.state.data) {
      var categoryBuildings = this.flattenTree(this.state.data).map((termEntity) => {
        return <CategoryBox key={termEntity.path} entity={termEntity} forceUpdate={ Math.random() } />
      })

      var categoryCounts
      if(this.state.filtersSelected.length) {
        categoryCounts = this.flattenTree(this.state.data).map((termEntity) => {
          const id = (termEntity.attributes.field_path ? termEntity.attributes.field_path.replace('/','') : termEntity.id)
          const di = (Constants.map[id].count ? Constants.map[id].count : { x: 0, y: 0})
      
          return  <g className="category-count" key={termEntity.path} transform={`translate(${di.x}, ${di.y})`}>
                    <polygon id="count" points="4 0 30 0 30 26 4 26 4 17 0 13 4 9 4 0" fill="#15af97"/>
                    <text x="17" y="18" textAnchor="middle">{termEntity.nodes.length}</text>
                  </g>
        })
      }
    }


    // <Directions />
    return (
      <div id="container-map" style={{width:"100%", height:"100%"}}>
        <SVGMap width={this.props.size.width} height={this.props.size.height}>
          <foreignObject width="1000" height="1000" x="0" y="0">
            <SVG src="/images/map.svg" uniquifyIDs={false} />
          </foreignObject>
          {categoryBuildings}
          {categoryCounts}
          {mainBuildings}
        </SVGMap>

        <div className="panels">
          <ToolFilters />
        </div>

        <Switch>
          <Route path="/welcome"       component={Welcome}    />
          <Route path="/info-centre"   component={InfoCentre} />
          <Route path="/library"       component={Library}    />
          <Route path="/category/:id"  component={Category}   />
          <Route path="/material/:id"  component={Material}   />
          <Route path="/direction/:id" component={Direction}  />
        </Switch>
      </div>
    )
  }
}

// update when language or filters change
const mapStateToProps = (state, ownProps) => ({
  language: state.language,
  filtersSelected: state.toolFiltersApplied,
  didDrag: state.didDrag,
  categoryNames: state.categoryNames,
})

Map = sizeMe({ monitorHeight: true })(Map)
Map = connect(mapStateToProps)(Map)

export default Map