export const Constants = {
  'title': 'DOIT toolbox',
  'infoPanel': {
    'width': 440,
    'breakpoint': 760
  },
  'map': {
    'direction': {
      x: 500,
      y: 500,
      w: 0,
      h: 0
    },
    'info-centre': {
      x: 472,
      y: 514,
      w: 139,
      h: 107
    },
    'library': {
      x: 626,
      y: 568,
      w: 167,
      h: 150
    },
    'sensitise': {
      x: 669,
      y: 280,
      w: 133,
      h: 110,
      count: {
        x: 766,
        y: 283,
      }
    },
    'explore': {
      x: 795,
      y: 373,
      w: 138,
      h: 227,
      count: {
        x: 901,
        y: 397,
      }
    },
    'work-together': {
      x: 291,
      y: 641,
      w: 219,
      h: 126,
      count: {
        x: 516,
        y: 716,
      }
    },
    'create': {
      x: 123,
      y: 556,
      w: 195,
      h: 132,
      count: {
        x: 323,
        y: 621,
      }
    },
    'reflect': {
      x: 79,
      y: 345,
      w: 151,
      h: 127,
      count: {
        x: 200,
        y: 371,
      }
    },
    'share': {
      x: 435,
      y: 153,
      w: 161,
      h: 142,
      count: {
        x: 508,
        y: 197,
      }
    },
    'scale-up': {
      x: 190,
      y: 160,
      w: 188,
      h: 198,
      count: {
        x: 383,
        y: 277,
      }
    }
  },
  'filterFieldMapping': { //TODO: get this configuration from backend: /jsonapi/field_config/field_config?filter[field_name][value]=field_group_size
    'target_audience': 'field_target_audience',
    //'age_group': 'field_age_group',
    //'group_size': 'field_group_size',
    //'time_frame': 'field_time_frame'
  }
}
export default Constants;
