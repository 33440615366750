import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Constants } from 'config/Constants.js'
import SVG from 'react-inlinesvg';


class CategoryBox extends React.Component {

  static contextTypes = {
    router: () => null
  }

  constructor(props) {
    super(props)
    this.state = {
      id: null,
      svg: null
    }
  }

  handleClick(e, id) {
    e.preventDefault()

    if(!this.props.didDrag) {
      this.props.history.push(`/category/${id}`)
    }
  }

  render() {
    //console.log(this.props.entity.nodes)

    const id = (this.props.entity.attributes.field_path ? this.props.entity.attributes.field_path.replace('/','') : this.props.entity.id)
    const di = (Constants.map[id] ? Constants.map[id] : { x: 0, y: 0, w: 0, h: 0 })

    //<style>{`#store-${id} path { fill:red; }`}</style>
    //<span className="count">{this.props.entity.nodes.length}</span>
    return (
      <foreignObject id={`store-${id}`} x={di.x} y={di.y} width={di.w} height={di.h}>
        <a href={`/category/${id}`} className="category-title-link" onClick={ (e) => { this.handleClick(e, id) } }>
          <SVG src={`/images/${id}.svg`} uniquifyIDs={false} />
        </a>
      </foreignObject>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  didDrag: state.didDrag
})
CategoryBox = connect(mapStateToProps)(CategoryBox)

export default withRouter(CategoryBox)
