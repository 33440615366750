
const Config = {
  endPoint : {
    host: "drupal.doit.ao.waag.org",
    port: "",
    path: ""

    // host: "www.doit-europe.net",
    // port: "",
    // path: ""
  }
}

export default Config;
