import React from 'react'
import ApiClient from 'client/ApiClient'
import ApiHelper from 'client/ApiHelper'
import { buildJSXFromHTML, isID} from "util/utility"
//import { Constants } from 'config/Constants.js'
import InfoPanel from "containers/InfoPanel/index.js"


class Direction extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      id: props.match.params.id,
      nodeEntity: null,
      includedEntities: null,
      termEntities: null
    }
  }

  componentDidMount() {
    this.update(this.props.match.params.id)
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.match.params.id !== nextProps.match.params.id) {
      this.setState({ id: nextProps.match.params.id })
      this.update(nextProps.match.params.id)
    }
  }

  update(id) {
    //get filter definitions to be able to lookup vocabulary names
    ApiHelper.instance().getFilterDefintions((definitions) => {
      this.setState({
        filterDefintions: definitions
      })
    })

    // set filter based we have a id or path
    var filter = (isID(id) ? id : { "field_path": "/direction/" + id })
    // full info on this node including relationships
    var includes = ["field_image"]

    ApiClient.instance().fetchContent("node--direction", filter, null, null, includes, 0, function(node, included) {
      // if we filtered by path we take the first node of the result
      if(typeof(filter) !== "string") {
        node = node[0]
      }
      //set content
      this.setState({
        nodeEntity: node,
        includedEntities: included
      })
      //lookup the terms in the hierarchy to get pathnames
      var categoryIds = ((((node || {}).relationships || {}).field_category || {}).data || []).map((cat) => {
        return cat.id
      })
      ApiHelper.instance().findTermInContentHierarchy(categoryIds, function(terms) {
        this.setState({
          termEntities: terms
        })
      }.bind(this))
    }.bind(this))
  }

  // render image or whatever
  renderFile(item, meta) {
    var mime = (item.attributes || {}).filemime
    var filename, url
    switch(mime) {
      case "image/jpeg":
        filename = (item.attributes || {}).filename
        url = ((item.attributes || {}).uri || {}).url
        return <img key={item.id} src={ApiClient.instance().getFullURL(url)} alt={filename} />
      case "application/pdf":
        filename = (item.attributes || {}).filename
        url = ((item.attributes || {}).uri || {}).url
        var label = (meta.description) ? meta.description : 'download tool'
        return <a className="button button-download" key={item.id} href={ApiClient.instance().getFullURL(url)} target="_blank" rel="noopener noreferrer">{label}</a>
      default:
        console.log("entity mime not supported:", mime)
        break
    }
    return null
  }

  render() {
    //show loading till we have fetched all
    var content

    //build content view when we have all data
    if(this.state.nodeEntity) {

      var title = this.state.nodeEntity.attributes.title || ""
      var color = "green"

      //make tool content
      var body = (this.state.nodeEntity.attributes.body || {}).value || ""
      body = buildJSXFromHTML(body)

      // includes
      var files = (this.state.includedEntities || [])
        .filter((item) => item.type === 'file--file')

      var images = (files || [])
        .filter((item) => item.attributes.filemime === "image/jpeg")
        .map((item) => { return this.renderFile(item) })

      content = (
        <div className="tool">
          {(images.length > 0 ? <div className="tool-images">{images}</div> : null )}
          {body}
        </div>
      )
    }

    return (
      <InfoPanel id={"direction"} title={title} color={color}>
        {content}
      </InfoPanel>
    )
  }
}

export default Direction
