import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
//import ToolSelectedList from './ToolSelectedList'
//import LanguageSelector from "containers/LanguageSelector"


class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      nav: false
    };
  }

  componentDidMount() {
    this.unsubscribeFromHistory = this.props.history.listen(this.handleLocationChange);
  }

  componentDidUnMount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  }

  handleLocationChange = (location) => {
    this.setState({
      nav: false
    })
  }

  componentDidUpdate() {
    //console.log("need to fetch info about the tools selected")
  }

  onNavToggle() {
    this.setState({
      nav: !this.state.nav
    })
  }


  render() {
    var navigation = (
      <div id="site-navigation">
        <div id="nav-toggle" onClick={() => {this.onNavToggle()}}><span className="fa icon"></span></div>
        <nav>
          <ul>
          </ul>
        </nav>
      </div>
    )

    var className = (this.state.nav === true ? "nav-toggled" : "")

    //<ToolSelectedList/>
    //<LanguageSelector/>
    return (
      <div id="site-header" className={className}>
        <div id="site-titles">
          <h1 id="site-title">DOIT toolbox</h1>
        </div>
        {navigation}
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})

Header = connect(mapStateToProps)(Header)
Header = withRouter(Header)

export default Header;
